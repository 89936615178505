
import { IonApp, IonRouterOutlet, IonToast } from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";

import store from "./store";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonToast,
  },
  setup() {
    document.body.classList.remove("dark");

    const alertIsOpen = ref(false);
    const setAlertOpen = (state: boolean) => (alertIsOpen.value = state);

    watch(store.alert, () => {
      if (store.alert.message) setAlertOpen(true);
    });

    return {
      alertIsOpen,
      setAlertOpen,
      store,
    };
  },
});
