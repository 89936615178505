
import {
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
} from "@ionic/vue";
import { onMounted, ref, watch } from "@vue/runtime-core";
import { calendar, cash, pricetags, settings, today } from "ionicons/icons";
import { useRoute } from "vue-router";

import store from "@/store";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonTitle,
    IonLoading,
  },
  setup() {
    const screenWidth = ref(0);

    const route = useRoute();

    const refresher = ref(0);

    watch(route, async (_, newRoute) => {
      if (newRoute.meta.refresh) {
        refresher.value += 1;
      }
    });

    onMounted(() => {
      screenWidth.value = window.innerWidth;
      window.addEventListener("resize", () => {
        screenWidth.value = window.innerWidth;
      });
    });

    return {
      icons: { calendar, cash, settings, today, pricetags },
      screenWidth,
      route,
      store,
      refresher,
    };
  },
};
